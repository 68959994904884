<template>
  <div class="app-container menus">
    <el-form
      :model="queryParams"
      ref="queryForm"
      v-hasPermi="['system:mem:list']"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
      style="width: 100%; height: 0.5%; text-align: left"
    >
      <el-form-item label="证书" prop="certificateDateId">
        <el-select
          v-model="queryParams.certificateDateId"
          placeholder="请选择证书"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in certificateDates"
            :key="dict.certificateDateId"
            :label="dict.certificateName"
            :value="dict.certificateDateId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="用户名称" prop="realName">
        <el-input
          v-model="queryParams.realName"
          placeholder="请输入用户名称"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="选择学校" prop="bumencid">
        <treeselect
          v-model="queryParams.bumencid"
          class="treeselect-main"
          :options="querySchool"
          :normalizer="normalizer"
          placeholder="选择学校"
          :disable-branch-nodes="true"
          loadingText
          noOptionsText="无数据"
          :show-count="true"
        />
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input
          v-model="queryParams.mobile"
          placeholder="请输入手机号"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="操作状态"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in userStatus"
            :key="dict.id"
            :label="dict.value"
            :value="dict.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="操作时间">
        <el-date-picker
          v-model="dateRange"
          size="small"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          v-hasPermi="['system:mem:list']"
          >搜索</el-button
        >
        <el-button
          icon="el-icon-refresh"
          size="mini"
          @click="resetQuery"
          v-hasPermi="['system:mem:list']"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:mem:exp']"
          >导出</el-button
        >
      </el-col>
      <el-col :span="1.5">
        <el-button
          v-if="state == 1"
          type="danger"
          plain
          @click="updateCensorshipCode(2)"
          style="margin-left: 10px"
          size="mini"
          >关闭审核</el-button
        >
      </el-col>
      <el-col :span="1.5">
        <el-button
          v-if="state == 2"
          type="success"
          plain
          @click="updateCensorshipCode(1)"
          style="margin-left: 10px"
          size="mini"
          >开启审核</el-button
        >
      </el-col>
    </el-row>

    <el-table
      ref="tables"
      :data="list"
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
      <!-- <el-table-column type="selection" width="55" align="center" /> -->
      <el-table-column label="用户编号" align="center" prop="id" />
      <el-table-column
        label="用户名称"
        align="center"
        prop="realname"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="学校所属"
        align="center"
        prop="area"
        width="150"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="所在学校"
        align="center"
        prop="school"
        width="150"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="手机号"
        align="center"
        prop="mobile"
        :show-overflow-tooltip="true"
      />
      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === '1'">正常</el-tag>
          <el-tag v-else-if="scope.row.status === '3'" type="success"
            >未审核</el-tag
          >
          <el-tag v-else-if="scope.row.status === '4'" type="info"
            >未通过</el-tag
          >
          <el-tag type="danger" v-else>停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="积分" align="center" prop="credits">
        <template slot-scope="scope">
          <el-button size="mini" plain @click="queryCredits(scope.row.id)">{{
            scope.row.credits
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="注册时间"
        align="center"
        prop="create_time"
        width="180"
      >
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
        width="180px"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 3"
            size="mini"
            type="text"
            icon="el-icon-edit-outline"
            @click="handleAudit(scope.row)"
            v-hasPermi="['system:mem:edit']"
            >审核</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:mem:edit']"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-view"
            @click="handleView(scope.row)"
            v-hasPermi="['system:mem:edit']"
            >证书</el-button
          >
          <el-button
            v-if="scope.row.parentId !== 0"
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:mem:delete']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
    <!-- 添加或修改对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form
        ref="userForm"
        :model="userForm"
        :rules="rules"
        label-width="80px"
        style="width: 100%; height: 100%"
      >
        <!--                <el-form-item prop="bumenpid" disabled="true" />-->
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户名称" prop="realname">
              <el-input
                v-model="userForm.realname"
                controls-position="right"
                :min="0"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="mobile">
              <el-input
                v-model="userForm.mobile"
                controls-position="right"
                :min="0"
                maxlength="11"
                autocomplete="off"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="选择学校" prop="bumencid">
              <treeselect
                v-model="userForm.bumencid"
                :options="schoolOptions"
                :disable-branch-nodes="true"
                :show-count="true"
                :normalizer="normalizer"
                placeholder="选择学校"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="QQ" prop="qq">
              <el-input
                v-model="userForm.qq"
                controls-position="right"
                :min="0"
                maxlength="20"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input
                type="email"
                v-model="userForm.email"
                controls-position="right"
                :min="0"
                maxlength="20"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-select v-model="userForm.sex" placeholder="请选择">
                <el-option
                  v-for="dict in userSex"
                  :key="dict.id"
                  :label="dict.value"
                  :value="dict.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-radio-group v-model="userForm.status">
                <el-radio :label="'1'">正常</el-radio>
                <el-radio :label="'2'">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="操作系统" prop="os">
              <el-input
                v-model="userForm.os"
                controls-position="right"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户平台" prop="email">
              <el-input
                v-model="userForm.container"
                controls-position="right"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="地址" prop="address">
              <el-input
                type="textarea"
                v-model="userForm.address"
                placeholder="请输入地址"
                maxlength="110"
                autocomplete="off"
                :rows="5"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 证书 -->
    <el-dialog
      title="证书"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <img
        style="width: 620px; height: 450px"
        :src="certificateObj.certificateUrl"
        alt=""
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deleteCertificate"
          >删除证书</el-button
        >
      </span>
    </el-dialog>
    <!-- 审核 -->
    <el-dialog title="审核" :visible.sync="auditVisible" width="500px">
      <el-form :model="auditForm" @submit.native.prevent>
        <el-form-item label="审核" required>
          <el-radio v-model="auditForm.state" label="1">通过</el-radio>
          <el-radio v-model="auditForm.state" label="4">未通过</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            auditVisible = false;
            auditForm = {};
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="auditUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request.js";
import {
  queryUserList,
  queryUserById,
  updateUserById,
  deleteUserById,
  userExportData,
  getCertificateByUid,
  deleteCertificateByUid,
  censorshipUser,
  getCensorshipCode,
  setCensorshipCode,
} from "@/api/user/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { schoolList } from "@/api/user/shcool";
import { download } from "@/utils/request";

export default {
  name: "User",
  components: { Treeselect },
  data() {
    return {
      // 总条数
      total: 0,
      loading: true,
      // 时间区间查询
      dateRange: [],
      ids: [],
      // 表格数据
      list: [],
      // 弹窗标题
      title: "修改会员",
      // 弹窗状态
      open: false,
      // 学校树选项
      schoolOptions: [],
      // 搜索学校树
      querySchool: [],
      // 搜索框是否显示
      showSearch: true,
      // 非多个禁用
      multiple: true,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        realName: "",
        bumencid: undefined,
        mobile: "",
        status: undefined,
        beginTime: undefined,
        endTime: undefined,
        certificateDateId: undefined,
      },
      userStatus: [
        { value: "正常", id: 1 },
        { value: "停用", id: 2 },
        { value: "未审核", id: 3 },
        { value: "未通过", id: 4 },
      ],
      userSex: [
        { value: "男", id: "0" },
        { value: "女", id: "1" },
        { value: "未知", id: "2" },
      ],
      // 用户信息集合
      userForm: {
        id: undefined,
        realname: undefined,
        mobile: undefined,
        sex: undefined,
        bumencid: undefined,
        avatar: undefined,
        qq: undefined,
        email: undefined,
        address: undefined,
        os: undefined,
        container: undefined,
        status: undefined,
      },
      rules: {
        realname: [
          {
            required: true,
            message: "用户名称不能为空",
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
        ],
        bumencid: [
          {
            required: true,
            message: "学校不能为空",
            trigger: "blur",
          },
        ],
      },
      certificateDates: [],
      certificateObj: {},
      dialogVisible: false,
      auditVisible: false,
      auditForm: {},
      eltState: null,
      state: null, //是否开启审核 1:开启 2：关闭
    };
  },
  async created() {
    await this.info();
    await this.getList();
    await this.getCensorshipCode();
    this.getSchoolOptions();
  },
  activated() {
    this.getList();
  },
  methods: {
    info() {
      let data = {
        pageSize: 100,
        pageNum: 1,
      };
      return request({
        url: "certificateDate/selectCertificateDate",
        method: "get",
        params: data,
      }).then((res) => {
        if (res.status === 200) {
          this.certificateDates = res.data.certificateDates;
          this.queryParams.certificateDateId =
            this.certificateDates[0].certificateDateId;
        }
      });
    },
    getCensorshipCode() {
      getCensorshipCode().then((res) => {
        this.state = res.data;
      });
    },
    updateCensorshipCode(code) {
      let str = code == 1 ? "开启" : "关闭";
      this.$confirm("是否" + str + "审核?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setCensorshipCode({ code }).then((res) => {
            console.log(res);
            if (res.status == 200) {
              if (code == 1) this.$message.success("开启成功");
              if (code == 2) this.$message.success("关闭成功");
              this.getCensorshipCode();
            } else {
              this.$message.error("操作失败，请重试");
            }
          });
        })
        .catch(() => {});
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = 10;
      // this.getSchoolOptions();
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.queryParams.beginTime = undefined;
      this.queryParams.endTime = undefined;
      this.resetForm("queryForm");
      this.queryParams.certificateDateId =
        this.certificateDates[0].certificateDateId;
      console.log(this.queryParams);
      this.handleQuery();
    },
    // 弹窗表单重置
    reset() {
      // 用户信息集合
      this.userForm = {
        id: undefined,
        realname: undefined,
        mobile: undefined,
        sex: undefined,
        bumencid: undefined,
        avatar: undefined,
        qq: undefined,
        email: undefined,
        address: undefined,
        os: undefined,
        container: undefined,
        status: undefined,
      };
      this.resetForm("userForm");
      this.schoolOptions = [];
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.title,
        children: node.children,
      };
    },
    // 多选框
    handleSelectionChange(val) {
      this.ids = val.map((item) => item.id);
      this.multiple = !val.length;
    },
    getSchoolOptions() {
      schoolList().then((res) => {
        if (sessionStorage.getItem("schoolIdList") == "undefined") {
          this.querySchool = this.handleTree(res.data, "id", "parentid");
        } else {
          let arr = sessionStorage.getItem("schoolIdList").split(",");
          let schoolArr = [];
          arr.forEach((item) => {
            res.data.forEach((school) => {
              if (school.id == item) schoolArr.push(school);
            });
          });
          this.querySchool = this.handleTree(schoolArr, "id", "parentid");
        }
      });
    },
    getList() {
      if (this.queryParams.certificateDateId == undefined) return;
      this.loading = true;
      const dataTime = this.dateRange;
      if (dataTime.length > 0) {
        this.queryParams.beginTime = dataTime[0];
        this.queryParams.endTime = dataTime[1];
      }
      let id = sessionStorage.getItem("userId");
      this.queryParams.adminId = id;
      queryUserList(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.pageBean.count;
        this.loading = false;
      });
    },
    // 审核
    handleAudit(row) {
      this.auditForm = {};
      this.auditForm.id = row.id;
      this.auditVisible = true;
    },
    auditUser() {
      if (this.auditForm.state == null) {
        this.$message.error("请选择审核状态");
        return;
      }
      censorshipUser(this.auditForm).then((res) => {
        if (res.status == 200) {
          this.getList();
          this.auditVisible = false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        }
      });
    },
    handleUpdate(row) {
      this.reset();
      schoolList().then((res) => {
        this.schoolOptions = this.handleTree(res.data, "id", "parentid");
      });
      const userId = row.id;
      queryUserById({ userId }).then((res) => {
        this.userForm = res.data;
      });
      this.open = true;
    },
    handleDelete(row) {
      const userId = row.id || this.ids;
      this.$confirm(
        '是否确认删除用户编号为"' + userId + '"的数据项？',
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteUserById({ userId }).then((res) => {
            if (res.status === 200) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 查看证书
    handleView(row) {
      getCertificateByUid(row.id).then((res) => {
        if (res.data) {
          this.certificateObj = res.data;
          this.dialogVisible = true;
        } else {
          this.$message.error("暂无证书记录");
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.certificateObj = {};
    },
    deleteCertificate() {
      this.$confirm(
        "是否确认删除用户编号为" + this.certificateObj.mid + "的证书记录",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteCertificateByUid({ uid: this.certificateObj.mid }).then(
            (res) => {
              if (res.status === 200) {
                this.$message({
                  type: "success",
                  message: "证书记录删除成功!",
                });
                this.dialogVisible = false;
              }
            }
          );
        })
        .catch(() => {});
    },
    // 导出按钮
    handleExport() {
      let id = sessionStorage.getItem("userId");
      this.queryParams.adminId = id;
      download(
        "user/userExportData",
        this.queryParams,
        `user_${new Date().getTime()}.xls`
      );
    },
    // 弹窗确定按钮
    submitForm() {
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          const form = this.userForm;
          updateUserById(form).then((res) => {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.open = false;
            this.getList();
          });
        }
      });
    },
    cancel() {
      this.open = false;
    },
    // 跳转到用户积分列表
    queryCredits(row) {
      this.$router.push({ path: "/Home/credits", query: { userId: row } });
    },
  },
};
</script>

<style scoped>
.app-container {
  padding: 20px;
}
.mb8 {
  margin: 0 -5px 8px;
}
.treeselect-main {
  width: 280px;
  margin-top: 5px;
  line-height: 30px;
}
.vue-treeselect >>> .vue-treeselect__placeholder {
  line-height: 30px;
}
.vue-treeselect >>> .vue-treeselect__control {
  height: 30px !important;
}
.vue-treeselect >>> .vue-treeselect__menu {
  overflow-x: auto !important;
}
.vue-treeselect >>> .vue-treeselect__label {
  overflow: unset;
  text-overflow: unset;
}
</style>
